@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;700&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', Helvetica, sans-serif;
}

html {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
}

html.ru {
  font-size: 13px;
}

body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  color: #272727;
  font-family: 'Poppins', Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html.ru * {
  font-family: 'Montserrat', sans-serif !important;
}

#root {
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
